<!--主页（为了对SEO友好，内容直接放静态页了）-->

<template>
  <section>
    <div></div>
  </section>
</template>

<script>
import $ from 'jquery';
import RegexToolbox from '../utils/regex.toolbox'
import Server from "../utils/server";
import QRCode from 'qrcode'
import WeixinJsapi from '../utils/weixin.jsapi'
import common from "@/utils/common";
import UploadCommon from "@/utils/upload.common";

export default {
  name: 'Home',
  data() {
    return {};
  },
  computed: {},
  setup() {
  },
  created() {  // 模板渲染成html前调用
    document.getElementById('home').style.display = 'block';
    // 设置最近访问的产品 URL
    this.LocalStorage.setLastAccessRoute('/');
    // Footer显隐设置
    common.setSeoFooterElementVisible(true)
  },
  mounted() {  // 模板渲染成html后调用
  },
  updated() {
  },
  components: {},
  methods: {}
}

</script>

<style lang="scss" scoped>
</style>
