<!--会员套餐包-->
<template>
  <section class="membership-package">
    <div>
      <h3 class="package-title" id="quantity-pack-title">
        <span style="font-size: 25px">包年套餐</span>
      </h3>
      <div class="packages">
        <div class="package" v-for="(pack,index) in classif.quantityPackages" :key="index">
          <div v-if="pack.sku==='Q06'" style="position:absolute;top:6px;right:7px">
            <img src="/images/tuijian.svg" width="28px" title="推荐"/>
          </div>
          <div class="top">
            <div class="membership-name">{{ pack.name }} {{ pack.quantityMembershipPoints }}点</div>
            <div class="price" v-if="pack.paymentCycle==='YEAR'">
              <div>
                <span class="price-unit">{{ pack.priceUnit }}</span>{{ pack.price }}<span class="period">/年</span>
              </div>
              <div style="line-height:16px">
                <span v-if="pack.sku==='Q05'" style="font-size:12px;color:#a1a1a1;">1点约￥0.028</span>
                <span v-if="pack.sku==='Q06'" style="font-size:12px;color:#a1a1a1;">1点约￥0.019</span>
                <span v-if="pack.sku==='Q07'" style="font-size:12px;color:#a1a1a1;">1点约￥0.016</span>
              </div>
              <div v-if="pack.sku==='Q07'" style="font-size:12px;">
                <div>可创建 20 个子账号</div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <b-button class="buy-btn" v-on:click="showPaymentFormModal(pack)">
              <span v-if="pack.paymentCycle==='WEEK'">立即试用</span>
              <span v-else>立即购买</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top:35px;">
      <h3 class="package-title">
        <span style="font-size: 25px">永久点数</span>
      </h3>
      <div class="packages">
        <div class="package" v-for="(pack,index) in classif.permanentQuantityPackages" :key="index"
             style="width:130px;">
          <div class="top" style="height:100px;">
            <div class="membership-name">{{ pack.quantityMembershipPoints }}点</div>
            <div class="price">
              <div>
                <span class="price-unit">{{ pack.priceUnit }}</span>{{ pack.price }}
              </div>
            </div>
          </div>
          <div class="bottom">
            <b-button class="buy-btn" v-on:click="showPaymentFormModal(pack)">
              <span v-if="pack.paymentCycle==='WEEK'">立即试用</span>
              <span v-else>立即购买</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div style="text-align:center;margin-top:50px;font-size:18px;">
      <img src="/images/green-check.png" class="green-check" width="18" alt="">
      所有套餐支持叠加使用，优先从快到期套餐中扣除点数。
    </div>

    <div class="rules usage-rule">
      <div class="card">
        <div class="title">点数与批处理</div>
        <table>
          <tr style="background-color:#f4f5f5;font-weight:bold">
            <td>产品名称</td>
            <td>点数消耗</td>
            <td>一次最多上传</td>
          </tr>
          <tr>
            <td>图片清晰吧</td>
            <td>
              <div>1点 / 张</div>
              <div style="font-size: 12px;">(保留细节 2点/张)</div>
            </td>
            <td>15张</td>
          </tr>
          <tr>
            <td>智能扩图吧</td>
            <td>
              <div>2点 / 张</div>
              <div style="font-size: 12px;">(超1600*1600 3点/张)</div>
            </td>
            <td>15张</td>
          </tr>
          <tr>
            <td>图片修复吧</td>
            <td>1点 / 张</td>
            <td>-</td>
          </tr>
          <tr>
            <td>图片去水印</td>
            <td>
              <div>1点 / 张</div>
              <div style="font-size: 12px;">(重绘 3点/张)</div>
            </td>
            <td>15张</td>
          </tr>
          <tr>
            <td>文字生图吧</td>
            <td>1点 / 张</td>
            <td>-</td>
          </tr>
          <tr>
            <td>图片生图吧</td>
            <td>2点 / 张</td>
            <td>8张</td>
          </tr>
          <tr>
            <td>一键抠图吧</td>
            <td>1点 / 张</td>
            <td>15张</td>
          </tr>
          <tr>
            <td>人物卡通化</td>
            <td>4点 / 张</td>
            <td>15张</td>
          </tr>
          <tr>
            <td>图片压缩吧</td>
            <td>1点 / 张</td>
            <td>50张</td>
          </tr>
          <!--            <tr>-->
          <!--              <td>生成相似图</td>-->
          <!--              <td>4点 / 张</td>-->
          <!--            </tr>-->
          <!--            <tr>-->
          <!--              <td>写真制作吧</td>-->
          <!--              <td>160点 / 张</td>-->
          <!--            </tr>-->
        </table>
      </div>
    </div>

    <br/>
    <br/>
    <!--        <div style="padding:15px 20px 5px 20px;text-align: center;">-->
    <!--            请注意：针对批量上传数用完的下载操作，我们正在制定流量收费规则！-->
    <!--        </div>-->

    <!-- 未登录用户付款 -->
    <b-modal id="membership-phone-form" ref="membership-phone-form" size="sm" centered hide-footer hide-header>
      <login-form :isbuying="true" v-bind:packageSku="selectionPackageSku"/>
    </b-modal>

    <!-- 登录用户付款 -->
    <b-modal id="logined-user-payment-form" ref="logined-user-payment-form" size="sm" centered hide-footer
             hide-header>
      <div class="renew-package-payment">
        <package-payment v-bind:packageSku="selectionPackageSku"/>
      </div>
    </b-modal>

    <!-- 微信付款二维码-->
    <b-modal id="weixin-payment-qrcode" ref="weixin-payment-qrcode" size="sm" centered hide-footer hide-header>
      <div style="text-align:center;">
        <img class="img" alt="微信付款二维码" v-bind:src="weixinQrcode" style="width:190px;">
        <div v-if="!isPaid">
          <span style="color:#dc3545;">{{ weixinPrice }}</span> 微信扫码
          <div style="font-size:12px;margin-top:8px;color:gray;">微信支付仅支持扫码</div>
        </div>
        <div v-else style="font-size:14px;color:#198f35;font-weight:bold;">付款成功，正在跳转......</div>
      </div>
    </b-modal>


    <b-alert v-model="showInfinitePackageTipMessageAlert" dismissible
             variant="primary" class="infinite-pack-msg-alert"
             v-html="infinitePackageTipMessage">
    </b-alert>
  </section>
</template>

<script>
import $ from 'jquery';
import LoginForm from "./LoginForm";
import Server from "../utils/server";
import PackagePayment from "./PackagePayment";
import common from "@/utils/common";

export default {
  name: 'MembershipPackage',
  components: {LoginForm, PackagePayment},
  data() {
    return {
      classif: {},
      selectionPackageSku: '',
      weixinQrcode: null,
      weixinPrice: null,
      isPaid: false,

      packOptions: [{'text': '不限张数', 'value': 'INFINITE', 'disabled': false}],
      monthPackOptionSelected: [],
      quarterPackOptionSelected: [],
      yearPackOptionSelected: [],
      showInfinitePackageTipMessageAlert: false
    };
  },
  computed: {
    infinitePackageTipMessage() {
      let amount = '';
      let paymentCycleName = '';
      if (this.monthPackOptionSelected.length > 0) {
        amount = '1GB';
        paymentCycleName = '月付';
      } else if (this.quarterPackOptionSelected.length > 0) {
        amount = '3GB';
        paymentCycleName = '季付';
      } else if (this.yearPackOptionSelected.length > 0) {
        amount = '15GB';
        paymentCycleName = '年付';
      }
      return `<span style="color:#db2828">${paymentCycleName}</span>不限张数说明：
                        超过每月可用张数后支持单张上传，耗低价流量，初始赠 <span style="color:#db2828">${amount}</span>`
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    if (document.getElementById('home')) document.getElementById('home').style.display = 'none';
    common.setSeoFooterElementVisible(false)

    this.weixinQrcode = null;
    this.weixinPrice = null;
    this.isPaid = false;
    this.$root.$refs.MembershipPackage = this;

    this.loadPackages();
  },
  mounted() {  // 模板渲染成html后调用

  },
  updated() {

  },
  methods: {
    getLoginUser: function () {
      return this.$root.$refs.RouteMenu.loginUser;
    },
    loadPackages: function () {
      const url = Server.getCloudServiceAddress() + '/membershipPackage/product';
      const resp = this.Ajax.get(url, false);
      if (resp.success) {
        this.classif = resp.data[0];
      }
    },

    showPaymentFormModal(pack) {
      this.selectionPackageSku = pack.sku;
      if (this.getLoginUser()) {
        this.showLoginedUserPaymentFormModal();
      } else {
        this.showPhoneFormModal()
      }
    },
    showPhoneFormModal() {
      this.$refs['membership-phone-form'].show()
    },
    hidePhoneFormModal() {
      this.$refs['membership-phone-form'].hide()
    },
    showLoginedUserPaymentFormModal() {
      this.$refs['logined-user-payment-form'].show()
    },
    hideLoginedUserPaymentFormModal() {
      this.$refs['logined-user-payment-form'].hide()
    },

    showWeixinPayQrcode(qrcode, price) {
      this.weixinQrcode = qrcode;
      this.weixinPrice = price;
      this.$refs['weixin-payment-qrcode'].show()
    },

    packOptionChange: function (selectedValues) {
      const self = this;

      // 显示无限张数提示
      self.showInfinitePackageTipMessageAlert = selectedValues.length > 0;

      // 重新勾选时，清理旧 timeout 防时间还没到就隐藏 alert
      if (this.showInfinitePackageTipMessageAlert) {
        if (self.timeoutId) clearTimeout(self.timeoutId);
        self.timeoutId = setTimeout(function () {
          self.showInfinitePackageTipMessageAlert = false;
        }, 8000);
      }

      // 清楚所有选项
      this.monthPackOptionSelected = [];
      this.quarterPackOptionSelected = [];
      this.yearPackOptionSelected = [];

      // 仅选中当前选择的
      switch (event.target.name) {
        case 'month-pack-option-checkbox':
          this.monthPackOptionSelected = selectedValues;
          break;
        case 'quarter-pack-option-checkbox':
          this.quarterPackOptionSelected = selectedValues;
          break;
        case 'year-pack-option-checkbox':
          this.yearPackOptionSelected = selectedValues;
          break;
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.membership-package {
  margin: 110px 15px 0 15px;
}

.package-title {
  margin: 2px 0;
  text-align: center;
  font-size: 28px;
}

.packages {
  display: block;
  text-align: center;
  margin-bottom: 20px;

  .package {
    width: 245px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;

    /*height: 486px;*/
    display: inline-block;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
    //color: #fff;
    font-weight: 500;
    margin-top: 20px;
    /*margin: 0 auto;*/

    .top {
      text-align: center;
      padding-top: 20px;
      height: 142px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      line-height: 32px;
      font-size: 20px;

      .membership-name {
        //font-size: 15px;
        //color: #f65152;
        font-weight: normal;
        font-size: 17px;
        margin-bottom: 4px;
      }

      .price {
        font-weight: bold;
        font-size: 35px;
        padding: 5px 0;

        .price-unit {
          font-size: 14px;
        }

        .period {
          font-size: 18px;
        }
      }
    }

    .center {
      padding: 18px 20px 1px 0;
      text-align: left;

      ul {
        color: #8d8d8d;
        font-size: 14px;

        > li {
          margin-bottom: 7px;
        }
      }
    }

    .bottom {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      margin-bottom: 22px;
      margin-top: 16px;
    }
  }


  .buy-btn {
    background: linear-gradient(90deg, rgb(255, 119, 0), rgb(255, 73, 0));
    box-shadow: rgba(255, 119, 0, 0.2) 0px 9px 13px 0px;
    vertical-align: top;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    border: none;
    cursor: pointer;
  }

}

.rules {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;

  .card {
    padding: 19px;
    margin: 10px;

    border-radius: 8px;
    -webkit-border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);

    //background: #3f3e3e;
    //color: #f8ddc5;
    //margin: 25px 10px 0 10px;
    //font-size: 13px;
    //line-height: 22px;

    .title {
      margin: 0 10px 15px 10px;
      //color: #fcf1e6;
      font-size: 20px;
      font-weight: bold
    }

    table, table tr th, table tr td {
      border: 1px solid #dedede;
      padding: 4px 11px;
    }

    table {
      width: 100%;
      min-height: 25px;
      line-height: 25px;
      text-align: center;
      border-collapse: collapse;
    }

    .icon-check {
      color: #ff7700;
      margin-right: 2px;
    }
  }
}

.pack-checkbox-group {
  display: inline-block;
  color: #DB2828;
  margin-left: 15px;
}

.pay-method {
  text-align: center;

  .pay-item {
    display: inline-block;
    cursor: pointer;
    text-align: center;
  }
}

.diff {
  color: #db2828;
}

.infinite-pack-msg-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  z-index: 1;
}

ul {
  list-style: none;
  padding: 0 15px;
}

@media only screen and (max-width: 360px) {
  .packages {
    .package {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .packages {
    .package {
      width: 96%;
    }
  }

  .usage-rule {
    .card {
      width: 96%;
    }
  }
}

</style>
