<template>
  <div class="my-favorites">
    我的收藏
  </div>
</template>

<script>

export default {
  name: 'MyFavorites',
  data() {
    return {};
  },
  computed: {},
  setup() {
  },
  created() {  // 模板渲染成html前调用
    if (document.getElementById('home')) document.getElementById('home').style.display = 'none';
    // 设置最近访问的产品 URL
    this.LocalStorage.setLastAccessRoute('/photoalbum/favorites');
  },
  mounted() {  // 模板渲染成html后调用

  },
  updated() {
  },
  methods: {}
}

</script>

<style lang="scss" scoped>


</style>
