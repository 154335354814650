import { render, staticRenderFns } from "./PackagePayment.vue?vue&type=template&id=93f13ff4&scoped=true"
import script from "./PackagePayment.vue?vue&type=script&lang=js"
export * from "./PackagePayment.vue?vue&type=script&lang=js"
import style0 from "./PackagePayment.vue?vue&type=style&index=0&id=93f13ff4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93f13ff4",
  null
  
)

export default component.exports