<!--提现-->
<template>
  <section class="withdrawal-form">

    <h4 class="title" v-bind:for-render="renderElement">收款账户信息</h4>
    <table>
      <tr>
        <td>
          <label style="white-space:nowrap;position:relative;top:4px;">支付宝账号：</label>
        </td>
        <td>
          <b-form-input v-model="alipayAccount" size="sm"></b-form-input>
        </td>
      </tr>
      <tr>
        <td>
          <label style="white-space:nowrap;position:relative;top:4px;">账户名：</label>
        </td>
        <td>
          <b-form-input v-model="alipayAccountName" size="sm"></b-form-input>
        </td>
      </tr>
    </table>
    <br/>

    <h4 class="title">提现金额</h4>
    <table>
      <tr>
        <td>
          <b-input-group prepend="￥" size="sm">
            <b-form-input type="number" name="withdrawal-amount" v-model="amount"
                          placeholder="满 200 元可申请提现">
            </b-form-input>
          </b-input-group>
        </td>
        <td>
          <a class="withdrawal-all" style="white-space:nowrap;" v-on:click="withdrawalAll">全部提取</a>
        </td>
      </tr>
    </table>
    <p style="margin:10px 0">审核通过后，款项将于 3 个工作日内发放至您的账户，请注意查收。</p>
    <b-button class="submit-btn" variant="primary" v-on:click="submit" size="sm">提交</b-button>
    <span class="form-check-msg" v-html="msg"></span>

  </section>
</template>

<script>
import $ from 'jquery';
import Server from "../utils/server";
import common from "@/utils/common";

export default {
  name: 'WithdrawalForm',
  data() {
    return {
      msg: null,
      alipayAccount: null,
      alipayAccountName: null,
      amount: null,
      alliance: null
    };
  },
  computed: {
    renderElement() {
      common.setSeoFooterElementVisible(false)
      return ''
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    if (document.getElementById('home')) document.getElementById('home').style.display = 'none';
  },
  mounted() {  // 模板渲染成html后调用
    this.alliance = this.loadAllianceAjax();
  },
  updated() {
  },
  components: {},
  methods: {
    withdrawalAll: function () {
      if (this.alliance) {
        this.amount = this.alliance.withdrawableAmount;
      } else {
        this.amount = 0;
      }
    },
    submit: function () {
      const self = this;
      if (!this.checkForm()) return;

      const registerReq = {
        allianceKey: this.alliance.key,
        account: this.alipayAccount,
        accountName: this.alipayAccountName,
        accountType: 'ZHIFUBAO',
        amount: this.amount
      };
      const url = Server.getCloudServiceAddress() + '/alliance/withdraw';
      const resp = self.Ajax.post(url, registerReq, false, '', '', '', '');
      if (resp.success) {
        // 隐藏模态框并刷新联盟号数据
        this.$root.$refs.Alliance.hideWithdrawalFormModal();
        this.$root.$refs.Alliance.refreshAlliance();
        // 重新刷新 alliance 数据
        this.alliance = this.loadAllianceAjax();
        // 延后 500 毫秒，否则1秒都不到提示就隐藏了
        setTimeout(function () {
          self.Common.toastSuccess(self.$bvToast, '提交成功', '审核通过后，预计3个工作日内到账！');
        }, 500)
      } else {
        self.msg = Server.getAjaxResponseMessage(resp);
      }
    },
    checkForm: function () {
      this.msg = '';
      if (!this.alipayAccount || this.alipayAccount.length === 0) {
        this.msg = '支付宝账号不能为空';
        return false;
      }
      if (!this.alipayAccountName || this.alipayAccountName.length === 0) {
        this.msg = '账户名不能为空';
        return false;
      }
      if (!this.amount || this.amount < 200) {
        this.msg = '提现金额未达￥200';
        return false;
      }
      return true;
    },
    loadAllianceAjax: function () {
      const url = Server.getCloudServiceAddress() + '/alliance';
      const resp = this.Ajax.get(url, false);
      if (resp.success) {
        return resp.data[0];
      }
      return null;
    },
  }
}

</script>

<style lang="scss" scoped>
.withdrawal-form {
  padding: 0 10px;

  .title {
    font-weight: bold;
    font-size: 20px;
    margin: 15px 0;
  }

  td, th {
    padding: 8px 0;
  }

  .withdrawal-all {
    color: #006df0;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    margin-left: 12px;
  }

  .btn-group-sm > .btn, .btn-sm {
    margin-left: 10px;
    margin-top: 30px;
    padding: 0.25rem 2rem;
  }

  .submit-btn {
    margin-bottom: 15px;
  }

  .form-check-msg {
    position: relative;
    top: 8px;
    left: 8px;
    color: #dc3545;
    width: 100%;
    margin-bottom: 0;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 10px;
  }
}

</style>
