<!--联盟营销-->
<template>
  <section class="alliance">
    <div v-bind:for-render="renderElement">

      <div v-if="alliance">
        <h4 style="margin-bottom:30px;">您的推广链接</h4>

        <div style="text-align: center;margin-bottom:30px;">
          <span id="alliance-link"
                style="background-color:#ededed;padding:6px 12px;border-radius:4px;margin-right:12px;">
                        https://www.tpqxb.com?a={{ alliance.key }}</span>
          <!-- <span id="alliance-link-copy-btn" v-on:click="copyAllianceLink">复制</span>-->
          <span class="royalty-ratio" style="color:#dc3545;font-size:13px;font-weight:400">此链返佣 {{ alliance.rebate }}%</span>
        </div>
        <hr/>

        <div class="stat-withdraw">
          <div class="stat-item-placeholder"></div>
          <div class="stat-item">
            <div class="describe">当前可提现金额</div>
            <div style="font-size:18px;color:#006EFF;font-weight:bold">
              {{ alliance.orderAmountUnit }}{{ alliance.withdrawableAmount }}
            </div>
          </div>
          <div class="stat-item">
            <div class="describe">提现中金额</div>
            <div style="font-size:18px;color:#006EFF;font-weight:bold">
              {{ alliance.orderAmountUnit }}{{ alliance.withdrawingAmount }}
            </div>
          </div>
          <div class="stat-item">
            <b-button variant="primary" v-on:click="showWithdrawalFormModal">申请提现</b-button>
          </div>
          <div class="stat-item">
            <a class="withdraw-history" v-on:click="showWithdrawListModal">查看提现</a>
          </div>
          <div class="stat-item-placeholder"></div>
        </div>
        <hr/>

        <div class="stat-order">
          <div class="stat-item-placeholder"></div>
          <div class="stat-item">
            <div class="describe">链接点击数</div>
            <div style="font-size:18px;color:#006EFF;font-weight:bold">{{ alliance.clickCount }}</div>
          </div>
          <div class="stat-item">
            <div class="describe">有效订单数</div>
            <div style="font-size:18px;color:#006EFF;font-weight:bold">{{ alliance.orderCount }}</div>
          </div>
          <div class="stat-item">
            <div class="describe">订单总金额</div>
            <div style="font-size:18px;color:#006EFF;font-weight:bold">
              {{ alliance.orderAmountUnit }}{{ alliance.orderAmount }}
            </div>
          </div>
          <div class="stat-item">
            <a style="color:#007bff;position:relative;top:14px;font-weight:400;cursor:pointer;"
               v-on:click="showTransactionListModal">查看订单</a>
          </div>
          <div class="stat-item-placeholder"></div>
        </div>
      </div>
      <div v-else>
        <img alt="图片清晰吧 推广联盟"
             src="/images/alliance_cooperation.webp" width="50px" style="margin-bottom:30px;">
        <h4 style="margin-bottom:30px;">推广联盟计划</h4>
        <p>加入推广联盟，从每笔订单中赚取 20% 佣金，新用户 3 个月内重复购买您都会得到提成。</p>
        <a class="generate-alliance-link" v-on:click="getAllianceLink">点击获取您的专属推广链接</a>
      </div>
    </div>

    <b-modal id="withdrawal-form-modal" ref="withdrawal-form-modal" size="lg" centered hide-footer hide-header>
      <withdrawal-form/>
    </b-modal>

    <b-modal id="withdrawal-list-modal" ref="withdrawal-list-modal" size="lg" centered hide-footer hide-header>
      <div v-if="alliance">
        <withdrawal-list v-bind:accumulatedWithdrawalAmount="alliance.accumulatedWithdrawalAmount"/>
      </div>
    </b-modal>

    <b-modal id="transaction-list-modal" ref="transaction-list-modal" size="lg" centered hide-footer hide-header>
      <transaction-list/>
    </b-modal>

  </section>
</template>

<script>
import $ from 'jquery';
import Server from "../utils/server";
import WithdrawalForm from "./WithdrawalForm";
import WithdrawalList from "./WithdrawalList";
import TransactionList from "./TransactionList";
import common from "@/utils/common";

export default {
  name: 'Alliance',
  components: {WithdrawalForm, WithdrawalList, TransactionList},
  data() {
    return {
      alliance: null
    };
  },
  computed: {
    renderElement() {
      common.setSeoFooterElementVisible(false)
      return ''
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    if (document.getElementById('home')) document.getElementById('home').style.display = 'none';
    this.$root.$refs.Alliance = this;
  },
  updated() {
  },
  mounted() {
    const self = this;
    this.alliance = this.loadAllianceAjax();
  },
  methods: {
    getLoginUser: function () {
      return this.$root.$refs.RouteMenu.loginUser;
    },
    copyAllianceLink: function () {
      const link = $('#alliance-link').text();
      navigator.clipboard.writeText(link).then(() => {
        this.Common.toastSuccess(this.$bvToast, '复制成功', '已复制到剪切板');
      }, () => {
        this.Common.toastDanger(this.$bvToast, '复制失败', '无法复制到剪切板');
      });
    },
    getAllianceLink: function () {
      if (this.getLoginUser()) {
        this.alliance = this.generateAllianceAjax();
      } else {
        this.Common.toastDanger(this.$bvToast, '温馨提示', '请先登录，若还没账号请联系客服获取，通过购买会员也能获取账号。');
      }
    },

    generateAllianceAjax: function () {
      const url = Server.getCloudServiceAddress() + '/alliance/generate';
      const resp = this.Ajax.get(url, false);
      if (resp.success) {
        return resp.data[0];
      } else {
        this.Common.toastDanger(this.$bvToast, '温馨提示', Server.getAjaxResponseMessage(resp));
      }
      return null;
    },
    refreshAlliance() {
      this.alliance = this.loadAllianceAjax();
    },
    loadAllianceAjax: function () {
      const url = Server.getCloudServiceAddress() + '/alliance';
      const resp = this.Ajax.get(url, false);
      if (resp.success) {
        return resp.data[0];
      } else {
        this.Common.toastDanger(this.$bvToast, '推广数据读取失败', Server.getAjaxResponseMessage(resp));
      }
      return null;
    },
    showWithdrawalFormModal() {
      this.$refs['withdrawal-form-modal'].show()
    },
    hideWithdrawalFormModal() {
      this.$refs['withdrawal-form-modal'].hide()
    },
    showWithdrawListModal() {
      this.$refs['withdrawal-list-modal'].show()
    },
    hideWithdrawListModal() {
      this.$refs['withdrawal-list-modal'].hide()
    },
    showTransactionListModal() {
      this.$refs['transaction-list-modal'].show()
    },
    hideTransactionListModal() {
      this.$refs['transaction-list-modal'].hide()
    },

  }
}

</script>

<style lang="scss" scoped>


.generate-alliance-link {
  color: #005ecb;
  cursor: pointer;
  font-weight: 300;
}

.generate-alliance-link:hover {
  color: #006EFF;
}

#alliance-link-copy-btn {
  color: #007bff;
  cursor: pointer
}

.alliance {
  padding: 0 20px 20px 20px;
  margin-top: 70px;
  text-align: center;

  // 允许选中文字
  -webkit-touch-callout: inherit;
  -webkit-user-select: text;
  -khtml-user-select: auto;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.withdraw-history {
  color: #007bff;
  position: relative;
  top: 8px;
  font-weight: 400;
  cursor: pointer;
}

.stat-withdraw {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
  margin-top: 12px;
}

.stat-order {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  line-height: 26px;
  font-size: 16px;
}

.stat-item {
  margin-top: 15px;
  min-width: 125px;
  flex: 1;
  margin-bottom: 12px;
}

/*此 class 用来调节 PC 和 WAP 布局的*/
.stat-item-placeholder {
  flex: 2;
}

@media only screen and (max-width: 600px) {
  .alliance {
    margin-top: 40px;
  }

  .stat-item-placeholder {
    flex: 0;
  }

  /*手机上无法使用此功能，因此暂时隐藏*/
  #alliance-link-copy-btn {
    display: none;
  }

  .royalty-ratio {
    display: block;
    margin-top: 10px;
  }
}

</style>
