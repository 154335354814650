export default {
    // eslint-disable-next-line no-useless-escape
    emailRegExp: /^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$/,
    phoneLikelyRegExp: /^[0-9]{7,15}$/,
    phoneCaptchaRegExp: /^[0-9]{4,6}$/,

    isPhoneLiteralLikely(s) {
        let result = false;
        if (!s || !s.trim()) return result;
        return this.phoneLikelyRegExp.test(s);
    },
    isEmailLiteral(s) {  //多个 email 用逗号隔开
        let result = false;
        if (!s || !s.trim()) return result;

        let emailCounter = 0;
        const items = s.split(',');
        for (var i = 0; i < items.length; i++) {
            const item = items[i];
            const isEmail = this.emailRegExp.test(item);
            if (isEmail) emailCounter++;
        }

        return emailCounter === items.length;
    },

    isPhoneCaptcha(s) {
        let result = false;
        if (!s || !s.trim()) return result;
        return this.phoneCaptchaRegExp.test(s);
    },

    isTeamMemberLiteral(s) {
        if (s.length === 11 && s.substring(0, 3) === '211') {
            return true
        }
        return false
    }
}