<!--销售伙伴-->
<template>
  <section class="team-member">
    <h4 class="title">子账号管理</h4>
    <div class="stat-item">
      <b-button variant="primary" size="sm" v-on:click="batchCreateTeamMembers">一键创建 5 个子账号</b-button>
    </div>
    <table id="team-member-table" class="compact hover" style="width:100%"></table>

    <!-- 备注表单 -->
    <b-modal id="remark-form" ref="remark-form"
             title="添加备注" ok-title="确定" cancel-title="取消"
             centered
             no-fade
             @ok="saveRemark">
      <b-form-input v-model="remark" placeholder=""></b-form-input>
      <div style="color:red;margin-top:12px;" v-html="errorMsg"></div>
    </b-modal>

  </section>
</template>

<script>
import $ from 'jquery';
import Server from "../utils/server";
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';
import LocalStorage from "../utils/localstorage";
import common from "@/utils/common";
import moment from 'moment'

export default {
  name: 'TeamMemberList',
  data() {
    return {
      itemUuid: null,
      remark: null,
      errorMsg: null
    };
  },
  computed: {
    renderElement() {
      common.setSeoFooterElementVisible(true)
      return ''
    },
    loginName() {
      return this.getLoginUser().loginName;
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    if (document.getElementById('home')) document.getElementById('home').style.display = 'none';
  },
  mounted() {  // 模板渲染成html后调用
    const self = this;

    this.dataTable = $('#team-member-table').DataTable({
      searching: false, //隐藏搜索框，默认会显示在表格右上角
      processing: false, // 设为 true 时,dom中设置的loading图标"r"会出来
      serverSide: true,
      paging: true,
      ordering: false,  // 显示排序
      info: true,      //显示“Showing 1 to 9 of 9 entries”
      deferLoading: 0,  // 不自动加载数据，必须将 serverSide 设为 true。
      bLengthChange: true, //显示每页大小的下拉框（显示一个每页长度的选择条（需要分页器支持））
      displayLength: 100,  //此值不要太大，否则手机端一屏容不下
      lengthMenu: this.Common.dataTablePageSize(),
      language: this.Common.dataTableLanguage(),
      pagingType: 'simple_numbers',
      rowId: 'uuid',
      //sort: self.get('tableSort'), //排序
      //colReorder: true,
      //order: [[0, 'desc']],   //如果不设置，默认按：order: [[0, 'asc']]
      //scrollX: true,
      //scrollY: "350px",        //使用这个，就表示固定高度，这里指定高度为250
      //scrollCollapse: true,    //结合 scrollY 使用
      autoWidth: false,  // 默认true，一旦设为true，表格整体宽度有可能会超过表格的容器
      dom: '<"dataTablesTop">tr<"' + 'dataTablesBottom' + '"<"left"i><"right">>',
      columns: [
        {title: '', data: "uuid", name: 'uuid', defaultContent: '', "visible": false},
        {title: '子账号名称', data: "username", name: 'username', defaultContent: ''},
        {
          title: '密码', data: "", name: '', defaultContent: '',
          render: function (data, type, row, meta) {
            return `主账号前8位`
          }
        },
        {title: '所属主账号', data: "identityName", name: 'identityName', defaultContent: ''},
        {
          title: '备注', data: "remark", name: 'remark', defaultContent: '',
          render: function (data, type, row, meta) {
            return self.Common.escapeHtml(data)
          }
        },
        {
          title: '创建时间', data: "createdTime", name: 'createdTime', defaultContent: '',
          render: function (data, type, row, meta) {
            return moment(data).format("YYYY-MM-DD HH:mm:ss")
          }
        },
        {
          title: '', data: "", name: '', defaultContent: '', width: '90px',
          render: function (data, type, row, meta) {
            return `<a class="action remark" data-uuid="${row.uuid}" style="margin-right:12px;cursor:pointer;">备注</a>
                    <a class="action delete" data-uuid="${row.uuid}" style="cursor:pointer;">删除</a>`
          }
        }
      ],
      ajax: {
        url: Server.getCloudServiceAddress() + '/account/teamMembers/',
        type: 'GET',
        dataSrc: 'data',
        beforeSend: function (request) {
          var loginUser = LocalStorage.getLoginUser();
          request.setRequestHeader("token", loginUser.sessionId);
        },
        data: function (d) {

          d.rows = d.length;  // length值就是定义表格时的 displayLength 字段值

          if (d.rows === -1) {
            delete d.start;
            delete d.rows;
          }

          delete d.columns;
          delete d.search;
          delete d.length;
          delete d.draw;
        },
        dataFilter: function (data) {
          let json = {total: 0};
          try {
            json = JSON.parse(data);
          } catch (e) {
            // 奇怪！有时会返回 html，而不是json。
          }
          json.recordsTotal = json.total;
          json.recordsFiltered = json.total;

          return JSON.stringify(json);
        }
      },
      initComplete: function (settings, json) {
      },
    });
    this.dataTable.on('xhr.dt', function (e, settings, json, xhr) {
      if (json.success) {
        if (!json.data) json.data = [];
        let data = json.data;
        if (!data) return;
        for (var i = 0; i < data.length; i++) {
          let row = data[i];
          row.remark = self.Common.escapeHtml(row.remark)
        }
      } else {
        let error = Server.getAjaxResponseMessage(json, '请购买团队版');
        self.Common.toastDanger(self.$bvToast, '错误', error);
      }
    });

    this.dataTable.on('click', 'tbody td .action', (e) => {
      const $target = $(e.target);
      const uuid = $target.data('uuid');
      if ($target.hasClass('remark')) {
        self.openRemarkModal(uuid);
      } else if ($target.hasClass('delete')) {
        self.deleteTeamMember(uuid);
      }
    });

    this.refreshDataTable();
  },
  updated() {
  },
  components: {},
  methods: {
    getLoginUser: function () {
      return this.$root.$refs.RouteMenu.loginUser;
    },
    refreshDataTable() {
      this.dataTable.ajax.reload();
    },
    batchCreateTeamMembers() {
      const url = Server.getCloudServiceAddress() + '/account/teamMembers/batchCreate';
      const resp = this.Ajax.post(url, null, false, '', '', '', '');
      if (resp.success) {
        this.refreshDataTable();
      } else {
        let error = Server.getAjaxResponseMessage(resp);
        this.Common.toastDanger(this.$bvToast, '错误', error);
      }
    },
    openRemarkModal(uuid) {
      this.itemUuid = uuid
      this.remark = null;
      this.errorMsg = null;
      this.$bvModal.show('remark-form')
    },
    deleteTeamMember(uuid) {
      const url = Server.getCloudServiceAddress() + `/account/teamMembers/${uuid}/delete`;
      const resp = this.Ajax.delete(url, false);
      if (resp.success) {
        let row = this.dataTable.row(`#${uuid}`)
        row.remove().draw();
      } else {
        let error = Server.getAjaxResponseMessage(resp);
        this.Common.toastDanger(this.$bvToast, '错误', error);
      }
    },
    saveRemark(bvModalEvent) {

      if (this.remark && this.remark.length > 20) {
        this.errorMsg = '长度不能超过 20'
        bvModalEvent.preventDefault()
      } else {
        this.errorMsg = null;
      }

      const req = {'uuid': this.itemUuid, 'remark': this.remark}
      const url = Server.getCloudServiceAddress() + `/account/teamMembers/${this.itemUuid}/remark`;
      const resp = this.Ajax.put(url, req, false, '', '', '', '');
      if (resp.success) {
        const user = resp.data[0];
        if (user) {
          // 根据 rowId 获取指定行索引，并更新 cell 内容
          const row = this.dataTable.row('#' + this.itemUuid);
          const rowIndex = row.index();
          this.dataTable.cell(rowIndex, 4).data(this.remark).draw();
        } else {
          const msg = Server.getAjaxResponseMessage(resp);
          this.Common.toastDanger(this.$bvToast, '错误', msg);
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.team-member {
  padding: 0 10px;

  .title {
    font-weight: bold;
    font-size: 20px;
    margin: 30px 0;
  }

  .dataTablesBottom {
    display: flex;
    border: 1px #dbdcdc solid;
    border-top: 0px;
    margin-top: -7px;
    padding: 5px 8px 0 8px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .left {
      flex: 1;
      padding-top: 2px;

      .dataTables_info {
        display: inline;
      }

      .dataTables_length {
        display: inline;
        margin: 3px 0 0 20px
      }
    }

    .right {
      flex: 1;
      text-align: right;
    }
  }

  .stat-item {
    margin-bottom: 22px;
  }

  .action {
    color: #007bff;
    position: relative;
    top: 8px;
    font-weight: 400;
    cursor: pointer !important;
  }

  // 允许选中文字
  -webkit-touch-callout: inherit;
  -webkit-user-select: text;
  -khtml-user-select: auto;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

</style>
