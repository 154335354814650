<!--登录-->

<template>
  <section v-bind:class=" {'login-form':!$attrs.isbuying,'login-buying-form':$attrs.isbuying} ">
    <div v-if="!$attrs.isbuying" style="text-align:center;margin-bottom:10px;">
      <img v-bind:src="imageUrl" alt="图个方便"
           style="margin-top:30px;margin-bottom:40px;height:45px;">
    </div>
    <div v-if="$attrs.isbuying" class="form-title">
      <div>登记付款</div>
      <div style="font-size:13px;margin-top:8px;">付款成功后账号自动创建</div>
    </div>
    <b-form inline>

      <div class="form-group">
        <b-input-group v-bind:prepend="nationCode">
          <b-form-input type="number" id="inline-form-input-name" v-bind:placeholder="phonePlaceholder"
                        v-model="phone">
          </b-form-input>
        </b-input-group>
      </div>

      <div class="form-group">
        <b-input-group>
          <b-form-input type="password" id="password" v-model="password" placeholder="密码">
          </b-form-input>
        </b-input-group>
      </div>
      <div class="form-group" v-if="$attrs.isbuying">
        <b-input-group>
          <b-form-input type="password" id="passwordConfirm" v-model="passwordConfirm" placeholder="确认密码">
          </b-form-input>
        </b-input-group>
      </div>

      <div class="form-group" v-if="$attrs.isbuying">
        <b-input-group append-html="<span class='login-form-request-captcha'>获取验证码</span>">
          <b-form-input type="number" id="login-captcha" placeholder="验证码">
          </b-form-input>
        </b-input-group>
        <div style="color:#dc3545;font-size:12px;margin-top:6px;">短信服务暂不可用，您可直接付款，后续认证！</div>
      </div>

      <p class="msg-group" v-html="msg"></p>

      <div class="desc-group" v-if="!$attrs.isbuying">
        <p>
          这是会员入口，请付费后登录
          <a class="join-image-membership" style="cursor:pointer;"
             v-on:click="routeToImagePackage">加入会员</a>
        </p>
      </div>

      <div class="form-bottom" v-if="!$attrs.isbuying">
        <a class="login-cancle-btn" variant="primary" v-on:click="cancleLogin">取消</a>
        <a class="forget-password-btn" variant="primary" v-on:click="forgetPassword">忘记密码</a>
        <b-button class="login" variant="primary" v-on:click="login">{{ loginText }}</b-button>
      </div>
    </b-form>
    <div v-if="$attrs.isbuying" style="text-align:center;margin-top:2px;margin-bottom:10px;">
      <b-button variant="outline-primary" style="padding:5px 20px;margin-right:8px;" v-on:click="doAlipay">
        <img src="/images/pay/alipay.webp" alt="支付宝支付" width="23px"/>
        <span style="position:relative;top:2px;padding-left:8px;">支付宝</span>
      </b-button>
      <b-button variant="outline-success" style="padding:5px 20px;margin-left:8px;"
                v-on:click="doWeixinpay">
        <img src="/images/pay/weixin.webp" alt="微信支付" width="23px"/>
        <span style="position:relative;top:1px;padding-left:8px;">微信</span>
      </b-button>

      <div v-if="this.Common.isMobileDevice()" class="pay-tips">
        <div>移动微信支付提醒：务必在微信手机客户端打开并支付</div>
        <div>移动支付宝付提醒：若无法打开支付宝请更新到最新版</div>
        <div>移动支付宝付提醒：微信客户端中无法使用支付宝支付</div>
      </div>
    </div>

  </section>
</template>

<script>
import $ from 'jquery';
import RegexToolbox from '../utils/regex.toolbox'
import Server from "../utils/server";
import QRCode from 'qrcode'
import WeixinJsapi from '../utils/weixin.jsapi'
import common from "@/utils/common";
import UploadCommon from "@/utils/upload.common";

export default {
  name: 'LoginForm',
  data() {
    return {
      imageUrl: '',
      nationCode: '+86',
      phone: '',
      password: '',
      passwordConfirm: '',
      loginText: '登录',
      msg: ''
    };
  },
  computed: {
    phonePlaceholder() {
      common.setSeoFooterElementVisible(false)
      return '手机号';
    },
    loginUser() {
      return this.getLoginUser();
    }
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    if (document.getElementById('home')) document.getElementById('home').style.display = 'none';
    UploadCommon.setPrivateImage(this, 'imageUrl', 'site/tgfb.webp')
  },
  mounted() {  // 模板渲染成html后调用
    const self = this;

    if (!this.$attrs.isbuying) {
      this.$root.$refs.RouteMenu.hideMe();
      this.msg = '';
    } else {
      this.msg = '';
    }

    $('.login-form-request-captcha').on('click', function (e) {
      //self.requestPhoneCaptcha();
    });

    // 自动填充缓存的手机号
    const cachedLoginName = self.LocalStorage.getLoginName();
    if (cachedLoginName) {
      this.phone = cachedLoginName;
    }

    // 自动填充URL上的手机号（优先级高于上面的）
    const loginName = self.Common.getQueryVariable(location.href, 'loginName');
    if (loginName) {
      this.phone = loginName;
    }

    // modal隐藏事件
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'weixin-payment-qrcode') {
        if (window.weixinOrderQueryIntervId) {
          clearInterval(window.weixinOrderQueryIntervId);
        }
      }
    })
  },
  updated() {
  },
  components: {},
  methods: {
    cancleLogin: function () {
      this.$root.$refs.RouteMenu.showMe()

      const lastAccessRoute = this.LocalStorage.getLastAccessRoute()
      switch (lastAccessRoute) {
        case "/cleaner":
          location.href = Server.getCloudServiceAddress(true) + '/cleaner';
          break;
        default:
          this.$router.push({path: lastAccessRoute})
      }
    },
    forgetPassword: function () {
      this.$router.push({path: '/forgetPassword'})
    },
    requestPhoneCaptcha: function () {
      this.msg = '';
      if (!this.phone || this.phone.length === 0) {
        this.msg = '请输入手机号';
        return;
      }
      if (!RegexToolbox.isPhoneLiteralLikely(this.phone)) {
        this.msg = '手机号格式错误';
        return;
      }
      if (!this.password || this.password.length === 0) {
        this.msg = '密码不能为空';
        return false;
      }
      if (this.password.length < 6) {
        this.msg = '密码太短，至少6位数';
        return false;
      }
      if (this.passwordConfirm.length === 0) {
        this.msg = '确认密码不能为空';
        return false;
      }
      if (this.password !== this.passwordConfirm) {
        this.msg = '两次密码输入不一致';
        return false;
      }

      const isTrialed = this.loadIsMembershipPackageTrialed(this.$attrs.packageSku, this.phone);
      if (isTrialed) {
        this.msg = '您已试用过';
      }
    },
    login: function () {
      this.msg = '';
      if (!this.phone || this.phone.length === 0) {
        this.msg = '请输入账号';
        return;
      }
      if (!RegexToolbox.isPhoneLiteralLikely(this.phone) && !RegexToolbox.isTeamMemberLiteral(this.phone)) {
        this.msg = '账号格式错误';
        return;
      }
      if (!this.password || this.password.length === 0) {
        this.msg = '密码不能为空';
        return;
      }
      if (this.password.length < 6) {
        this.msg = '密码太短，至少6位数';
        return;
      }

      this.loginAjax()
    },
    loginAjax: function () {
      const self = this;
      if (this.loginText === '登录中') return;

      this.msg = '';
      this.loginText = '登录中';

      const loginReq = {
        username: this.phone,
        password: this.password
      };

      setTimeout(function () {
        const url = Server.getCloudServiceAddress() + '/account/login';
        const resp = self.Ajax.post(url, loginReq, false, '', '', '', '');
        self.loginText = '登录';

        if (resp.success) {
          const user = resp.data[0];
          if (user) {
            self.LocalStorage.saveLoginUser(user);
            self.$root.$refs.RouteMenu.updateLoginUser();
            self.$root.$refs.RouteMenu.showMe();
            self.$root.$refs.RouteMenu.gotoLastAccessPage();
            self.LocalStorage.setLoginName(user.loginName);
          } else {
            self.msg = Server.getAjaxResponseMessage(resp);
          }
        } else {
          self.msg = Server.getAjaxResponseMessage(resp);
        }
      }, 500);
    },
    loadIsMembershipPackageTrialed: function (sku, loginName) {
      const url = Server.getCloudServiceAddress() + `/membershipPackage/${sku}/${loginName}/trialed`;
      const resp = this.Ajax.get(url, false);
      if (resp.success) {
        return resp.data[0];
      } else {
        this.msg = Server.getAjaxResponseMessage(resp);
      }
      return null;
    },
    routeToImagePackage: function () {
      this.$root.$refs.RouteMenu.showMe();
      this.$router.push({path: '/package'})
    },
    getLoginUser: function () {
      return this.$root.$refs.RouteMenu.loginUser;
    },

    doAlipay: function () {

      if (this.Common.isMobileDevice() && common.isWeChat()) {
        alert('支付宝无法在微信应用里使用，请使用浏览器访问我们的网站，然后发起支付宝付款')
        return;
      }

      if (!this.checkForm()) return;

      this.registerAjax().then(registerReq => {
        let allianceKey = this.$root.$refs.RouteMenu.getAllianceKeyCookie();

        let identityName = registerReq.phone;
        let packageSku = registerReq.packageSku;
        let query = `?packSku=${packageSku}&identityName=${identityName}&allianceKey=${allianceKey}`;

        let payurl;
        if (this.Common.isMobileDevice()) {
          payurl = Server.getCloudServiceAddress(true) + '/pay/alipay/wap' + query;
        } else {
          payurl = Server.getCloudServiceAddress(true) + '/pay/alipay/pc' + query;
        }
        location.href = payurl;
      }, () => {
      });
    },
    doWeixinpay: function () {
      const self = this;

      if (this.Common.isMobileDevice() && !common.isWeChat()) {
        alert('微信支付提醒：（1）需在微信中访问此网站，然后点支付；（2）您也可以使用PC电脑访问网站，此时会发起微信扫码付款')
        return;
      }

      if (!this.checkForm()) return;

      this.registerAjax().then(registerReq => {
        let packageSku = registerReq.packageSku;
        let identityName = registerReq.phone;

        if (this.Common.isMobileDevice()) {
          // 静默获取用户 openId，获取到的 openId 存放在后台缓存 1 分钟，
          // 以便在重定向的静态页面调用 "pay/weixin/wap" 时可以根据 state 找到 openId 用于调起支付。
          const state = this.Common.generateUuid() + "_" + packageSku + "_" + identityName;
          WeixinJsapi.requestCode(state);
        } else {
          let allianceKey = this.$root.$refs.RouteMenu.getAllianceKeyCookie();
          let query = `?packSku=${packageSku}&identityName=${identityName}&allianceKey=${allianceKey}`;
          let payurl = Server.getCloudServiceAddress() + '/pay/weixin/pc' + query;
          const resp = this.Ajax.get(payurl, false);
          if (resp.success && resp.data[0]) {
            const d = resp.data[0];

            QRCode.toDataURL(d.codeUrl)
                .then(url => {
                  // base64 字符串
                  //console.log(url)
                  const membershipPackage = this.$root.$refs.MembershipPackage;
                  membershipPackage.showWeixinPayQrcode(url, d.displayPrice);

                  window.weixinOrderQueryIntervId = setInterval(function () {
                    const url = Server.getCloudServiceAddress() + '/pay/weixin/order/' + d.outTradeNo + '/success';
                    self.Ajax.get(url).then(resp => {
                      if (resp.success && resp.data[0] === true) {
                        membershipPackage.isPaid = true;
                        setTimeout(function () {
                          location.href = Server.getCloudServiceAddress(true) + '/login?loginName=' + identityName;
                        }, 3000)
                      }
                    }, () => {
                    });
                  }, 3000);
                })
                .catch(err => {
                })
          } else {
            this.msg = Server.getAjaxResponseMessage(resp);
          }
        }
      }, () => {
      });
    },
    checkForm: function () {
      this.msg = '';
      if (!this.phone || this.phone.length === 0) {
        this.msg = '请输入账号';
        return false;
      }
      if (!RegexToolbox.isPhoneLiteralLikely(this.phone) && !RegexToolbox.isTeamMemberLiteral(this.phone)) {
        this.msg = '账号格式错误';
        return false;
      }

      if (!this.password || this.password.length === 0) {
        this.msg = '密码不能为空';
        return false;
      }
      if (this.password.length < 6) {
        this.msg = '密码太短，至少6位数';
        return false;
      }
      if (this.passwordConfirm.length === 0) {
        this.msg = '确认密码不能为空';
        return false;
      }
      if (this.password !== this.passwordConfirm) {
        this.msg = '两次密码输入不一致';
        return false;
      }

      return true;
    },
    registerAjax: function () {
      const self = this;
      this.msg = '';

      const registerReq = {
        phone: this.phone,
        password: this.password,
        packageSku: this.$attrs.packageSku
      };

      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          const url = Server.getCloudServiceAddress() + '/account/registerBuy';
          const resp = self.Ajax.post(url, registerReq, false, '', '', '', '');
          if (resp.success) {
            resolve(registerReq);
          } else {
            self.msg = Server.getAjaxResponseMessage(resp);
            reject();
          }
        }, 500);
      });
    },
  }
}

</script>

<style lang="scss" scoped>

.form-group {
  width: 100%;

  input {
    height: 45px;
  }
}

.form-inline .form-group {
  margin-bottom: 10px;
}

.input-group-text {
  background-color: transparent;
}

.form-inline .custom-select, .form-inline .input-group {
  width: 100%;
}

#login-captcha {
  border-right: 0;
}

.login-form {
  margin: 0 auto;
  min-height: 440px;
  background: #fff;
  display: block;
  flex-shrink: 0;

  width: 100%;
  border: none;
  margin-top: 5%;
  padding: 20px 20px 21px;
}

.login-buying-form {
  /*margin-top: 2px;*/
}

.form-title {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 1.5;
  color: #222;
  margin-bottom: 25px;
  margin-top: 8px;
}

.form-bottom {
  width: 100%;
  margin-top: 20px;

  .login-cancle-btn {
    float: left;
    color: #1a73e8;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    top: 8px;
  }

  .login-cancle-btn:hover {
    color: #135ab6;
  }

  .forget-password-btn {
    float: left;
    color: #5f6368;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    top: 8px;
    margin-left: 20px;
  }

  .forget-password-btn:hover {
    color: #37393c;
  }

  .login {
    float: right;
    cursor: pointer;
    padding: 5px 30px;
  }

}

.msg-group {
  width: 100%;
  height: 2em;
  margin-bottom: 0;
  font-size: 13px;
  color: #dc3545;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
}

.desc-group {
  color: #5f6368;
  margin-top: 10px;
  display: block;
  width: 100%;
}

.tips {
  color: #dc3545;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
}

.btn-outline-primary {
  border-color: #05B4FD;
}

.btn-outline-primary:hover {
  background-color: #007bff;
  border-color: #05B4FD;
}

/*去掉input[type="number"]的默认样式*/
//-----------------------------------

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

//-----------------------------------

@media only screen and (min-width: 576px) {
  .login-form {
    width: 400px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    margin-top: 120px;
    padding: 58px 40px 25px;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 10px;
  }
}

</style>

<style>
.login-form-request-captcha {
  cursor: pointer;
}

.login-form-request-captcha:hover {
  color: #9c9c9c;
}

.pay-tips {
  color: #DB2828;
  font-size: 12px;
  margin-top: 15px;
}

</style>
