export default {

    fileToBlob(file, fileType) {
        let blob = URL.createObjectURL(file)
        if (fileType) {
            blob = blob.slice(0, blob.size, fileType)
        }
        return blob
    },

    getImageFileRatio(file) {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);   //读取图像文件 result 为 DataURL, DataURL 可直接 赋值给 img.src
            reader.onload = function () {
                if (reader.readyState === 2) {
                    const img = new Image();
                    // 对于 image/tiff 格式图片，经查谷歌浏览器支持的不好，因此不会触发 img.onload() 事件
                    img.src = reader.result;
                    img.onload = function () {
                        resolve({width: this.width, height: this.height, image: img});
                    };
                }
            };
        });
    },

    // URL 转 File
    getFileFromUrl(url, fileName, fileType) {
        return new Promise((resolve, reject) => {
            var blob = null;
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.setRequestHeader('Accept', fileType);
            xhr.responseType = "blob";
            // 加载时处理
            xhr.onload = () => {
                // 获取返回结果
                blob = xhr.response;
                let file = new File([blob], fileName, {type: fileType});
                // 返回结果
                resolve(file);
            };
            xhr.onerror = (e) => {
                reject(e)
            };
            // 发送
            xhr.send();
        });
    },

    // File 转 Image
    getImageFromFile(file) {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);   //读取图像文件 result 为 DataURL, DataURL 可直接 赋值给 img.src
            reader.onload = function () {
                if (reader.readyState === 2) {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = function () {
                        resolve(img);
                    };
                }
            };
        });
    },

    // URL 转 Image（即 HTMLImageElement 对象）
    getImageFromUrl(url) {
        return new Promise(resolve => {
            const htmlImageElement = new Image();
            htmlImageElement.src = url;
            htmlImageElement.crossOrigin = 'anonymous'
            htmlImageElement.onload = function () {
                resolve(htmlImageElement);
            };
        })
    },

    // Image 转 DataURL
    getDataURLFromImage(img) {
        return new Promise((resolve, reject) => {
            try {
                let canvas = document.createElement('canvas')
                canvas.width = img.width;
                canvas.height = img.height
                let ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, img.width, img.height)
                resolve(canvas.toDataURL())
            } catch (e) {
                reject(e)
            }
        })
    },

    // DataURL 转 File
    dataURLtoFile(dataURL, fileName) {
        const arr = dataURL.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        // eslint-disable-next-line no-plusplus
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], fileName, {type: mime})
    },

    // 修改图片分辨率
    modifyImageResolution(image, imageName, originalWidth, originalHeight, targetWidth, targetHeight) {
        // 缩小绘制图像，参考：https://www.runoob.com/try/try.php?filename=tryhtml5_canvas_drawimage3
        const canvas = document.createElement('canvas')
        // 必须给 canvas 设置长宽，否则默认绘制的图像长宽是 300x150
        canvas.width = targetWidth
        canvas.height = targetHeight
        const ctx = canvas.getContext('2d')

        // 注意：image 不能换成 file
        ctx.drawImage(image, 0, 0, originalWidth, originalHeight, 0, 0, targetWidth, targetHeight)

        // image canvas 转 file
        const dataURL = canvas.toDataURL(image.type)
        return this.dataURLtoFile(dataURL, imageName)
    },

    // 降低图片分辨率（注意：降低分辨率后的文件大小可能反而更大，此外 image/tiff 不要调用此方法，支持不好）
    // 提醒：前端处理后会导致图片深度等质量缩水，因此不建议在前端使用。
    reduceImageResolution(file, limitWidth, limitHeight) {
        const reduceFileInfo = {file: file, width: null, height: null, isReduced: false}

        const self = this
        return new Promise(resolve => {
            self.getImageFileRatio(file).then(ratio => {
                const {image, width, height} = ratio
                reduceFileInfo.width = width;
                reduceFileInfo.height = height;

                // 1. 检查是否需要降低分辨率
                let needReduce = (width * height) > (limitWidth * limitHeight)
                if (!needReduce) {
                    resolve(reduceFileInfo)
                    return
                }

                // 2. 获取原图长宽比
                const scale = width / height

                // 3. 检查长边，超过则缩小到限制值
                let w = width
                let h = height
                let needReduceLongSide = false
                if (width > height && width > limitWidth) {
                    w = limitWidth
                    h = Math.floor(w / scale)
                    needReduceLongSide = true
                } else if (width <= height && height > limitWidth) {
                    h = limitWidth
                    w = Math.floor(h * scale)
                    needReduceLongSide = true
                }

                // 4. 再次检查是否需要降低分辨率
                needReduce = (w * h) > (limitWidth * limitHeight)
                if (!needReduce) {
                    if (needReduceLongSide) {
                        reduceFileInfo.file = this.modifyImageResolution(image, file.name, width, height, w, h)
                        reduceFileInfo.width = w
                        reduceFileInfo.height = h
                        reduceFileInfo.isReduced = true
                    }
                    resolve(reduceFileInfo)
                    return
                }

                // 5. 检查短边，超过则缩小到限制值
                let needReduceShortSide = false
                if (w > h && h > limitHeight) {
                    h = limitHeight
                    w = Math.floor(h * scale)
                    needReduceShortSide = true
                } else if (w <= h && w > limitHeight) {
                    w = limitHeight
                    h = Math.floor(w / scale)
                    needReduceShortSide = true
                }

                // 6. 根据缩小的短边降低分辨率
                if (needReduceShortSide) {
                    reduceFileInfo.file = this.modifyImageResolution(image, file.name, width, height, w, h)
                    reduceFileInfo.width = w
                    reduceFileInfo.height = h
                    reduceFileInfo.isReduced = true
                }
                resolve(reduceFileInfo)
            })
        })
    },

    // 降低图片分辨率直到指定大小（参数单位：MB）
    reduceImageResolutionForSize(file, limitSize) {
        return new Promise((resolve, reject) => {
            this.reduceImageResolutionForSizeWithResolve(file, limitSize, resolve, reject)
        })
    },
    reduceImageResolutionForSizeWithResolve(file, limitSize, resolve, reject) {
        const self = this;
        if (!limitSize) {
            const reduceFileInfo = {file: file, width: null, height: null, isReduced: false}
            resolve(reduceFileInfo)
            return
        }

        // 通过每次降低 30% 的分辨率来达到降低图片大小目的
        this.getImageFileRatio(file).then(ratio => {
            const {image, width, height} = ratio
            //console.log(`>>> 大小: ${Math.floor(file.size / 1024 / 1024)}，分辨率：${width}x${height}`)
            if (file.size > limitSize * 1024 * 1024) {

                // 通过每次降低 20%或50% 的分辨率来达到降低图片大小目的（注意：经测按大小比例降低分辨率，效果并不好）
                let reductionPercentage
                if (file.size > 6 * 1024 * 1024) {
                    reductionPercentage = 0.5
                } else {
                    reductionPercentage = 0.8
                }
                //console.log(`>>> 降低分辨率百分比：${reductionPercentage}`)

                const w = Math.floor(width * reductionPercentage)
                const h = Math.floor(height * reductionPercentage)
                const modifiedFile = self.modifyImageResolution(image, file.name, width, height, w, h)
                modifiedFile._isReduced = true
                self.reduceImageResolutionForSizeWithResolve(modifiedFile, limitSize, resolve, reject)
            } else {
                const reduceFileInfo = {file: file, width: width, height: height, isReduced: !!file._isReduced}
                resolve(reduceFileInfo)
            }
        })
    }
}